.projects-section {
    padding: 80px 0;
    background-color: #f9f9f9;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    gap: 20px;
}

.filter-buttons button {
    padding: 12px 24px;
    background: transparent;
    border: 1px solid #333;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.filter-buttons button.active,
.filter-buttons button:hover {
    background: #333;
    color: #fff;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 30px;
}

.project-card {
    position: relative;
    overflow: hidden;
    border-radius: 1px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: translateY(-5px);
}

.project-image {
    position: relative;
    aspect-ratio: 4/3;
    overflow: hidden;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.project-card:hover .project-image img {
    transform: scale(1.1);
}

.project-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    padding: 30px;
    color: white;
    transform: translateY(100%);
    transition: transform 0.7s ease;
}

.project-card:hover .project-overlay {
    transform: translateY(0);
}

.project-overlay h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
}

.project-overlay p {
    font-size: 16px;
    margin-bottom: 15px;
    opacity: 0.9;
}

.project-details {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    opacity: 0.8;
}

@media (max-width: 768px) {
    .projects-grid {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    
    .filter-buttons {
        flex-wrap: wrap;
    }
} 