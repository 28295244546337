@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
.rolling-flags {
    overflow: hidden;
    white-space: nowrap;
    padding: 20px 0;
}

.flags-container {
    display: inline-block;
    animation: roll 100s linear infinite;
    padding-bottom: 3rem;
}

.flag {
    display: inline-block;
    margin: 0 30px;
}

.flag img {
    height: 100px; /* Adjust this value to change the flag size */
    width: auto;
    vertical-align: middle;
    object-fit: contain;
    border-radius: 9px;
}

.rolling-flags text{
    font-size: 1.1rem;
}

.rolling-flags h2 {
    font-family: "Prata", serif;
    padding-bottom: 3rem;
}

@keyframes roll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}