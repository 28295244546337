@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

.contact-cta {
    background-color: #f5f5f5;
    padding: 4rem 1rem;
    margin: 2rem 0;
}

.cta-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.cta-container h2 {
    font-family: "Prata", serif;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #1a1a1a;
}

.cta-container p {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
}

.cta-button {
    padding: 1rem 2.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
    /* border-radius: 1px; */
    cursor: pointer;
    transition: all 0.8s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Montserrat", sans-serif;
}

.cta-button:hover {
    background-color: transparent;
    color: #1a1a1a;
}

@media screen and (max-width: 768px) {
    .contact-cta {
        padding: 3rem 1rem;
    }

    .cta-container h2 {
        font-size: 2rem;
    }

    .cta-container p {
        font-size: 1rem;
    }
} 