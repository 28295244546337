@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

/* Base styles for the Navbar */
.NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    width: 95%;
    height: 80px;
    border-radius: 8px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    transition: background-color 0.5s ease-in-out;
}

.NavbarItems.scrolled {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

/* Styles for the navbar logo */
.navbar-logo {
    color: white;
    font-size: 2rem;
    cursor: pointer;
    font-family: "Prata", serif;
}

/* Styles for the nav menu */
.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}

/* Styles for the nav links */
.NavbarItems .nav-links {
    text-decoration: none !important;
    color: #fafafa77;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.NavbarItems .nav-links:hover {
    color: #fff;
    border-radius: 1px;
    transition: all 0.3s ease-in-out;
}

/* Styles for the dropdown menu */
.dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background-color: black;
    padding: 0;
    margin: 0;
    list-style: none;
    top: 65px;
    border-radius: 7px;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

/* Styles for displaying the dropdown menu on hover */
.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

/* Styles for the dropdown links */
.NavbarItems .dropdown-link {
    text-decoration: none !important;
    color: white;
    font-size: 1rem;
    padding: 1rem 1.5rem;
    display: block;
    white-space: nowrap;
}

.NavbarItems .dropdown-link:hover {
    transition: 0.4s ease-in-out;
    background-color: #444;
}

/* Styles for the menu icons */
.menu-icons {
    display: none;
}

/* Media queries for responsiveness */
@media screen and (max-width: 850px) {
    .NavbarItems {
        z-index: 99;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: black;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.7s ease-in-out;
        border-radius: 13px;
    }

    .nav-menu.active {
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.7s ease-in-out;
    }

    .NavbarItems .nav-links {
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .NavbarItems .nav-links:hover {
        transition: none;
    }

    .menu-icons {
        display: block;
        cursor: pointer;
    }

    .menu-icons i {
        font-size: 1.2rem;
        color: white;
    }
}
