@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
.hero {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 110;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid h1 {
    padding-top: 4rem !important;
}

img {
    width: 100%;
    height: 750px;
    /* object-fit: cover; */
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.hero-text h1 {
    font-size: 3rem;
    font-weight: 800;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Prata", serif;
}

.hero-text p {
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 0 2rem 0;
}

.hero-text .show {
   text-decoration: none;
   background: #fff;
   padding: 1rem 2rem;
   border-radius: 6px;
   font-size: 1.1rem;
   font-weight: bold;
   letter-spacing: 2px;
   color: #222;
}

.hero-text .hide {
    display: none;
}


@media screen and (max-width: 555px) {
    .hero-text h1 {
        padding: 10px 20px;
    }

    .hero-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    .hero-text .show {
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
        background: red;

    }
    
}