@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    /* z-index: -1000; */
}

.App{
    font-family: sans-serif;
    text-align: center;
}