@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
.aboutus {
    margin: 4rem 6rem;
}

.aboutus h1 {
    font-size: 3rem;
}

.first-aboutus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4.5rem;
}

.first-aboutus-reverse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 4.5rem;
}

.aboutus-text {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;   
}

.aboutus-text h2 {
    font-family: "Prata", serif;
 padding-bottom: 1rem;
}

.image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.image img {
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1p 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.image img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}


@media  screen and (max-width:850px) {
    .aboutus {
        margin: 4rem 2rem;
    }

    .first-aboutus {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .first-aboutus-reverse {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4.5rem;
    }

    .aboutus-text {
        width: 100%;
    }

    .image {
        width: 100%;
        margin: 1.8rem 0;
    }

    .image img {
        height: 250px;
    }


}