@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

.blog-container {
    margin: 4rem 6rem;
    min-height: 100vh;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 2.5rem;
    padding: 1rem;
}

.blog-card {
    background: white;
    border: 1px solid #eaeaea;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.blog-image {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.blog-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.blog-card:hover .blog-image img {
    transform: scale(1.05);
}

.blog-content {
    padding: 1.5rem;
}

.blog-category {
    font-size: 0.9rem;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
}

.blog-content h2 {
    font-family: "Prata", serif;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #1a1a1a;
}

.blog-content p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

.blog-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    border-top: 1px solid #eaeaea;
}

.blog-date {
    color: #888;
    font-size: 0.9rem;
}

.read-more {
    color: #1a1a1a;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.read-more:hover {
    color: #666;
}

@media screen and (max-width: 850px) {
    .blog-container {
        margin: 4rem 2rem;
    }

    .blog-grid {
        grid-template-columns: 1fr;
    }
} 