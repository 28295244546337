.collection-container {
    padding: 60px;
  }
  
  .collection-filter-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-item {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease-in-out;
  }
  
  .filter-item.active {
    border: 2px solid #111;
  }
  
  .collection-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
  }
  
  .image-item {
    overflow: hidden;
    position: relative;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .image-item:hover img {
    transform: scale(1.1);
  }
  

  .learn-more-button{
    margin-top: 60px;
    margin-bottom: -40px;
  }

  .learn-more-button button{
    background-color: white;
    color: black;
    border: 1px solid black;
  }

  .learn-more-button:hover button{
    background-color: black;
    color: white;
    border: 1px solid white;
  }