.custom-button {
    background: #000;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff; /* Default text color */
    background-color: #000; /* Default background color */
    border: 1px solid #fff; /* Thin border */
    cursor: pointer;
    transition: background-color 0.7s, color 0.7s; /* Smooth transition for hover effect */
  }
  
  .custom-button:hover {
    background-color: #fff; /* Background color on hover */
    color: #000; /* Text color on hover */
    border-color: white;
  }