.form-container {
    align-items: center;
    justify-content:center;
    margin: 4rem 6rem;
    color: #2a2a2a
}

.form-container form {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}

.form-container input {
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;
}

.form-container textarea {
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;
}

.form-container button{
    height: 2.5rem; /* Reduce the height */
    width: 10rem;
    padding: 0 1.5rem; /* Adjust padding */
    border-radius: 1;
    border: 1px solid #2a2a2a;
    background-color: #fff; /* Ensure background color is set */
    color: #2a2a2a; /* Text color */
    cursor: pointer; /* Change cursor to pointer */
    transition: 0.4s ease-in-out; /* Smooth transition for border color */
}

.form-container button:hover {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.social-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
.social-button {
    width: 100%;
    max-width: 320px;
    padding: 12px 20px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 10px;
    border: none;
    color: white;
    transition: background-color 0.3s ease;
    text-align: center;
    line-height: 1.2;
  }
  
  .whatsapp-button:hover {
    background-color: #25D366 !important;
  }
  
  .wechat-button:hover {
    background-color: #7BB32E !important;
  }



@media screen and (max-width: 850px) {
    .form-container {
        margin: 4rem 2rem;
    }

    .form-container form {
        padding-top: 2rem;
        width: 90%;
    }

    .social-button {
        max-width: 90%;
    }
}