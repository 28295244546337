@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
.fotter-header{
  font-family: "Prata", serif;
}
.footer {
    background-color: black;
    color: white;
    padding: 2rem 4rem;
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;  
}

.top i {
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
}

.bottom {
   padding-top: 2rem;
   text-align: start;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}

.bottom div {
  display: flex;
  flex-direction: column;
}

.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0 .8rem 0;
}

.bottom a {
    text-decoration: none;
    color: #fafafa;
    padding-bottom: 0.4rem;
    font-size: 1.1rem;
}


@media screen and (max-width: 850px) {
  .footer {
    padding: 4rem 2rem;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }

  .bottom div {
    width: 50%;
  }
} 

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.footer-logo h1 {
  margin-bottom: 0.5rem;
  font-family: "Prata", serif;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.footer-section h4 {
  margin-bottom: 1rem;
}

.footer-section a, .footer-section p {
  display: block;
  margin-bottom: 0.5rem;
  color: #ccc;
  text-decoration: none;
}

.footer-email {
  display: flex;
}

.footer-email input {
  padding: 0.5rem;
  background-color: #333;
  border: none;
  color: white;
}

.footer-email button {
  padding: 0.5rem 1rem;
  background-color: #4a4a4a;
  border: none;
  color: white;
  cursor: pointer;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #333;
  padding-top: 1rem;
}

.social-icons a {
  margin-right: 1rem;
  color: white;
  font-size: 1.2rem;
}

.footer-info {
  display: flex;
  align-items: center;
}

.footer-info a, .footer-info p {
  margin-left: 1rem;
  color: #ccc;
  text-decoration: none;
}