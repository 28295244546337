@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

.carousel {
    width: 100%;
    margin: auto;
    position: relative;
    background-color: black; /* Set background color to black */
    padding: 70px; /* Optional: add padding to give some space around the carousel */
}
.carousel h1{
    color: white;
    margin-bottom: 20px;
    font-family: "Prata", serif;
}
.carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform 0.5s;
    padding: 20px; 
    box-sizing: border-box;
}

.carousel-item img {
    max-width: 80%;
    height: auto;
    margin: 0 auto;
}

.carousel-caption {
    font-size: 1em;
    color: white;
}

.learn-more-button{
    margin-top: 60px;
    margin-bottom: -40px;
}

.slick-center .carousel-item {
    transform: scale(1.1);
}

/* Change the color of the dots */
.slick-dots li button:before {
  color: white;
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
  color: white;
}
